// jQuery
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

// Raphael
var Raphael = require('raphael');

// Typeahead
import 'typeahead.js';

// ChartJS
import 'chart.js';
window.Chart = require('chart.js');

// Jquery easing
import 'jquery.easing';

// JqueryUI
import 'jquery-ui';
import 'jquery-ui/ui/widgets/sortable'

// Slimscroll
import 'jquery-slimscroll';

// Morris
import 'morris.js/morris.min';

// Fancybox
import 'fancybox/dist/js/jquery.fancybox';

// Ionicons
import 'ionicons';

// Daterangepicker
import 'daterangepicker';

// Moment
window.moment = require('moment');

// Datatables
import JSZip from 'jszip';
window.JSZip = JSZip;
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import 'datatables.net-bs/js/dataTables.bootstrap.min';
import 'datatables.net/js/jquery.dataTables.min';
import 'datatables.net-responsive-dt/js/responsive.dataTables.min';
import 'datatables.net-buttons';
import 'datatables.net-select';
import 'datatables.net-rowreorder';
require('datatables.net-buttons/js/dataTables.buttons.min')(); // Column visibility
require('datatables.net-buttons/js/buttons.colVis.js')(); // Column visibility
require('datatables.net-buttons/js/buttons.print.js')(); // Print view button
require('datatables.net-buttons/js/buttons.html5.js');

// Select2
import 'select2';

// AdminLTE
import 'admin-lte';

// Custom icons on BoxWidget
$(function () {
    $(".btn-box-tool").click(function (e) {
        $(this).parents('.box').toggleClass('panel-fullscreen');
        $(this).children('i').toggleClass('icon-add icon-minus');
    });
})

// Bootstrap
import 'bootstrap-datepicker';
import 'bootstrap-sass';
$(function () {
    $('[data-toggle="tooltip"]').tooltip();

    $.fn.dataTable.defaults.drawCallback = function () {
        $('[data-toggle="tooltip"]').tooltip();
    };
})

// Vue
import * as Vue from 'vue/dist/vue.esm-bundler'
window.Vue = Vue;

// Classnames
import classNames from 'classnames';
window.classNames = classNames;
